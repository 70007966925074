// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-index-tsx": () => import("./../../../src/pages/media/index.tsx" /* webpackChunkName: "component---src-pages-media-index-tsx" */),
  "component---src-pages-methodology-index-tsx": () => import("./../../../src/pages/methodology/index.tsx" /* webpackChunkName: "component---src-pages-methodology-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-take-action-index-tsx": () => import("./../../../src/pages/take-action/index.tsx" /* webpackChunkName: "component---src-pages-take-action-index-tsx" */),
  "component---src-templates-criteria-criteria-tsx": () => import("./../../../src/templates/criteria/criteria.tsx" /* webpackChunkName: "component---src-templates-criteria-criteria-tsx" */),
  "component---src-templates-embedded-competition-state-score-competition-state-score-tsx": () => import("./../../../src/templates/embedded/competition-state-score/competition-state-score.tsx" /* webpackChunkName: "component---src-templates-embedded-competition-state-score-competition-state-score-tsx" */),
  "component---src-templates-embedded-criteria-map-criteria-map-tsx": () => import("./../../../src/templates/embedded/criteria-map/criteria-map.tsx" /* webpackChunkName: "component---src-templates-embedded-criteria-map-criteria-map-tsx" */),
  "component---src-templates-embedded-overall-map-overall-map-tsx": () => import("./../../../src/templates/embedded/overall-map/overall-map.tsx" /* webpackChunkName: "component---src-templates-embedded-overall-map-overall-map-tsx" */),
  "component---src-templates-embedded-overall-state-score-overall-state-score-tsx": () => import("./../../../src/templates/embedded/overall-state-score/overall-state-score.tsx" /* webpackChunkName: "component---src-templates-embedded-overall-state-score-overall-state-score-tsx" */),
  "component---src-templates-embedded-participation-state-score-participation-state-score-tsx": () => import("./../../../src/templates/embedded/participation-state-score/participation-state-score.tsx" /* webpackChunkName: "component---src-templates-embedded-participation-state-score-participation-state-score-tsx" */),
  "component---src-templates-embedded-polarization-state-score-polarization-state-score-tsx": () => import("./../../../src/templates/embedded/polarization-state-score/polarization-state-score.tsx" /* webpackChunkName: "component---src-templates-embedded-polarization-state-score-polarization-state-score-tsx" */),
  "component---src-templates-embedded-representation-state-score-representation-state-score-tsx": () => import("./../../../src/templates/embedded/representation-state-score/representation-state-score.tsx" /* webpackChunkName: "component---src-templates-embedded-representation-state-score-representation-state-score-tsx" */),
  "component---src-templates-embedded-sor-map-sor-map-tsx": () => import("./../../../src/templates/embedded/sor-map/sor-map.tsx" /* webpackChunkName: "component---src-templates-embedded-sor-map-sor-map-tsx" */),
  "component---src-templates-media-category-media-category-tsx": () => import("./../../../src/templates/media-category/media-category.tsx" /* webpackChunkName: "component---src-templates-media-category-media-category-tsx" */),
  "component---src-templates-media-post-media-post-tsx": () => import("./../../../src/templates/media-post/media-post.tsx" /* webpackChunkName: "component---src-templates-media-post-media-post-tsx" */),
  "component---src-templates-read-the-report-read-the-report-tsx": () => import("./../../../src/templates/read-the-report/read-the-report.tsx" /* webpackChunkName: "component---src-templates-read-the-report-read-the-report-tsx" */),
  "component---src-templates-state-competition-state-competition-tsx": () => import("./../../../src/templates/state-competition/state-competition.tsx" /* webpackChunkName: "component---src-templates-state-competition-state-competition-tsx" */),
  "component---src-templates-state-of-reform-state-of-reform-tsx": () => import("./../../../src/templates/state-of-reform/state-of-reform.tsx" /* webpackChunkName: "component---src-templates-state-of-reform-state-of-reform-tsx" */),
  "component---src-templates-state-overall-state-overall-tsx": () => import("./../../../src/templates/state-overall/state-overall.tsx" /* webpackChunkName: "component---src-templates-state-overall-state-overall-tsx" */),
  "component---src-templates-state-participation-state-participation-tsx": () => import("./../../../src/templates/state-participation/state-participation.tsx" /* webpackChunkName: "component---src-templates-state-participation-state-participation-tsx" */),
  "component---src-templates-state-polarization-state-polarization-tsx": () => import("./../../../src/templates/state-polarization/state-polarization.tsx" /* webpackChunkName: "component---src-templates-state-polarization-state-polarization-tsx" */),
  "component---src-templates-state-representation-state-representation-tsx": () => import("./../../../src/templates/state-representation/state-representation.tsx" /* webpackChunkName: "component---src-templates-state-representation-state-representation-tsx" */)
}

